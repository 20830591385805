<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['patchLocations'])">
      <multi-answer-question
        title="label.patchLocations"
        :value="patchLocations"
        :options="$options.patchLocationsOptions"
        :other-value="otherPatchLocations"
        dysfunction="skin-texture"
        @input="onFieldChange('patchLocations', $event)"
        @other-input="onFieldChange('otherPatchLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  PATCH_LOCATIONS,
  OTHER_VALUE_INCLUSIVE_BINDINGS
} from '@/modules/questionnaire/api/constants';

const PATCH_LOCATION_OPTIONS = [
  { value: PATCH_LOCATIONS.SCALP, text: 'patchLocation.scalp' },
  { value: PATCH_LOCATIONS.FACE, text: 'patchLocation.face' },
  { value: PATCH_LOCATIONS.EARS, text: 'patchLocation.ears' },
  { ...OTHER_VALUE_INCLUSIVE_BINDINGS }
];

export default {
  name: 'PatchLocationsTemplate',
  components: { QuestionWithErrorWrap, MultiAnswerQuestion },
  patchLocationsOptions: PATCH_LOCATION_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    patchLocations: {
      type: Array,
      default: () => []
    },
    otherPatchLocations: {
      type: String,
      default: ''
    }
  }
};
</script>
